import PropTypes from "prop-types";
import { ContactMediaList } from "./style";
import { Row, Col } from "react-bootstrap";

const GetInTouchArea = ({ icon, title, content, data }) => {
    const addressList = data.address;
    return (
        <ContactMediaList>
            {/* <div className="icon">
                <span className={icon}></span>
            </div> */}
            {/* <div className="content">
                <span className="text">{title}:</span>
                <a href="/" className="number">
                    {content}
                </a>
            </div> */}
            <div className="content">
                <span className="text title mb-5">{data.country}</span>
                <Row className="d-block">
                    {addressList.map((item, index) => {
                        const addList = item.text;
                        return (
                            <Col key={index} className="d-block">
                                <div className="d-flex mb-4 align-items-center">
                                    <div className="icon">
                                        <span className={item.icon}></span>
                                    </div>
                                    <div>
                                        {addList.map((item, index) => {
                                            return (
                                                <div
                                                    className="number"
                                                    key={index}
                                                >
                                                    {item.content}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
                <a href="/" className="number">
                    {}
                </a>
            </div>
        </ContactMediaList>
    );
};
GetInTouchArea.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.string,
};
export default GetInTouchArea;
